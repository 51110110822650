/* TOOLBOX */
.important {
  color: red;
}

body {
  min-height: 100vh;
  background-color: #282c34 !important;
  padding-bottom: 5rem;
}

.App {
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App h2 {
  font-size: 5rem;
  margin: 4rem 0;
}

#accueil,
#education {
  padding: 3rem
}


.col-md-9 {
  padding-left: 50px !important
}

.profile-photo {
  border-radius: 50%;
}

p,
h3 {
  text-align: left;
}

p {
  font-size: 1.4rem;
}

#education+.row>div,
#professionnel+.row>div {
  margin-bottom: 3rem;
}

#education+.row ul {
  list-style: none;
  padding: 0
}

p span.badge {
  margin: 5px;
  color: #333 !important;
  border: 4px solid #333;
  background-color: #DDD !important;
}

/* span.badge.python {
  color: #4B8BBE !important;
  border: 4px solid #4B8BBE;
  background-color: #FFFFDE !important;
} */